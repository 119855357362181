<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        类型: <el-select class="width_100" v-model="search.type">
        <el-option :value="-1" label="全部"></el-option>
        <el-option :value="0" label="VIP收益"></el-option>
        <el-option :value="1" label="流水收益"></el-option>
      </el-select>
      </div>
      <div class="search-box">
        账号:
        <el-input class="width_200" v-model="search.username" placeholder="请输入账号"></el-input>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="order" label="订单号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="username" label="账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="类型" align="center" :show-overflow-tooltip="true">
        <template v-slot="scope">
          <span v-if="scope.row.type == 0">VIP收益</span>
          <span v-else>流水收益</span>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="金额" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="addtime" width="200px" label="统计时间" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          {{$common.timeToDate(scope.row.addtime)}}
        </template>
      </el-table-column>
      <el-table-column prop="remark"  label="备注" align="center" :show-overflow-tooltip="true"></el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
  </div>
</template>

<script>
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "agentLog",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 10 },
      tableData: [], //数据
      search: {
        username:"",
        type:-1,
      },
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    getList(){
      let params = {
        pageid:this.pager.page - 1,
        pcount:this.pager.rows,
        username:this.search.username,
        type:this.search.type,
      }
      httpClient("getAgentLog").post(params).then((res)=>{
        if (res.code == 0 ){
          this.tableData = res.data.list
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }
      })
    }
  },
};
</script>
